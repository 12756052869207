.pagination {
    border-radius: $border-radius-min;

    .page-item {
        &:first-child .page-link, &:last-child .page-link {
            border-radius: $border-radius-min;
        }

        .page-link {
            color: $primary-color;
        }

        &.active {
            .page-link {
                background-color: $primary-color;
                border-color: $primary-color;
                color: $white;
            }
        }
    }
}
