.range-slider {
    position: relative;
    padding: 0;
    width: 100%;

    &:before {
        content: attr(data-min);
        position: absolute;
        top: 20px;
        left: 0;
        transform: translate(-50%);
    }

    &:after {
        content: attr(data-max);
        position: absolute;
        top: 20px;
        right: 0;
        transform: translate(50%);
    }

    .range-slider-fill {
        height: 8px;
        border-radius: 0;
        background: none;//linear-gradient(to right, $warning-color, $success-color);
    }

    .range-slider-rail {
        height: 8px;
        border-radius: 0;
        background: $warning-color;
    }

    .range-rec-rail {
        display: block;
        position: absolute;
        width: 100%;
        top: calc(50% - 4px);
        left: 0;
        height: 8px;
        background: $success-color;
        z-index: 0;
    }

    .range-outer-rail {
        display: block;
        position: absolute;
        width: 100%;
        top: calc(50% - 4px);
        left: 0;
        height: 8px;
        background: $light-gray;
        z-index: 0;
    }

    .range-slider-knob {
        &:after {
            content: attr(data-val);
            position: absolute;
            top: -22px;
            right: 50%;
            //margin-right: -50%;
            transform: translate(50%);
        }
    }
}
