$white: #ffffff !default;
$blue: #525afc !default;
$azure: #3380F7;
$purple: #8b95fa !default;
$red: #df2f4d !default;
$green: #46ad5e !default;
$dark-orange: #e69958 !default;
$orange: #f4cc46 !default;
$yellow: #f4cc46 !default;
$black: #263c61 !default;
$cherry: #da0063;

//$bg-color: #ffffff !default;

$light-blue: lighten($blue, 10%) !default;
$light-azure: lighten($azure, 10%) !default;
$light-purple: lighten($purple, 10%) !default;
$light-green: lighten($green, 10%) !default;
$light-orange: lighten($orange, 10%) !default;
$light-red: lighten($red, 10%) !default;

$light-gray: #e9ebef !default;//rgba($black, 0.1) !default;
$medium-gray: #858687 !default;
$dark-gray: #4a4a4a !default;

$bg-color: #fcfcfc !default;

$placeholder-gray: rgba($black, 0.8) !default;
$input-border-gray: rgba($black, 0.3) !default;
$border-gray: rgba($black, 0.2) !default;

$primary-color: $black !default;
$default-color: $blue !default;
$secondary-color: $medium-gray !default;
$info-color: $azure !default;
$success-color: $green !default;
$warning-color: $orange !default;
$danger-color: $red !default;
