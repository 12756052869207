.page-loader {
    position: absolute;
    width: 100%;
    min-height: 150px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 1;

    * {
        animation-play-state: running;
    }

    &.fixed {
        position: fixed;
        height: 100vh;
    }

    &:before {
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        border-radius: 50%;
        content: "";
        border: 4px solid rgba($primary-color, 0.2);
        border-top-color: $primary-color;
        animation: loader-spin .7s infinite linear;
    }
}

.loader {
    position: relative;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 1;
    overflow: hidden;

    * {
        animation-play-state: running;
    }

    &:before {
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        margin: 18px auto;
        top: -3px;
        left: calc(50% - 12px);
        border-radius: 50%;
        content: "";
        border: 3px solid rgba($primary-color, 0.2);
        border-top-color: $primary-color;
        animation: loader-spin .7s infinite linear;
    }
}

@keyframes loader-spin {
    100% {
        transform: rotate(360deg);
    }
}
