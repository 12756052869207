.form-steps-section {
  width: 60px;
  max-width: 60px;
  margin-top: -7px;
  margin-left: -60px;

  .form-steps {
    position: sticky;
    display: flex;
    top: 15px;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;

    .step {
      position: relative;
      display: list-item;
      width: 48px;
      height: 48px;
      border: 2px solid rgba($black, 0.5);
      border-radius: 24px;
      color: rgba($black, 0.5);
      font-size: 24px;
      text-align: center;
      line-height: 48px;
      margin: 1.5vh 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:not(:first-child):before, &:not(:last-child):after {
        position: absolute;
        left: 50%;
        display: block;
        content: '';
        width: 1px;
        height: 2vh;
        background: rgba($black, 0.5);
      }

      &:before {
        bottom: calc(46px + 0.5vh);
        z-index: 1;
      }

      &:after {
        top: calc(46px + 0.5vh);
        z-index: 2;
      }

      &.passed {
        color: $white;
        border-color: darken($success-color, 14);
        background: $success-color;
        //cursor: pointer;

        &:before, &:after {
          background: $success-color;
        }
      }

      &.current {
        color: $white;
        border-color: darken($warning-color, 14);
        background: $warning-color;
        cursor: default;

        &:after {
          background: $warning-color;
        }

        &.passed {
          &:before, &:after {
            background: $success-color;
          }
        }
      }
    }
  }

  &.guest-form-steps-section {
    .form-steps {
      .step {
        width: 42px;
        height: 42px;
        font-size: 22px;
        line-height: 42px;
        margin: 8px 0;
        cursor: pointer;

        &:not(:last-child):after {
          height: 16px;
        }

        &:before {
          display: none;
        }

        &:after {
          top: 40px;
        }

        &.screenshot-active:before {
          content: "";
          position: absolute;
          display: block;
          width: 0;
          background: transparent;
          height: auto;
          right: 0;
          top: 50%;
          border: 22px solid transparent;
          border-left: 0;
          border-right: 22px solid #263c61;
          transform: translate(calc(100% + 4px), -50%);
        }
      }
    }

    .screenshot-placeholder {
      background: #fff;
      position: absolute;
      top: 0;
      left: 72px;
      width: 1000px;
      z-index: 9999;
      padding: 0;
      display: none;
      opacity: 0;
      border: 1px solid #263c61;
      box-shadow: 0 1px 3px rgb(38 60 97 / 5%), 0 1px 2px rgb(38 60 97 / 10%);
      transition: all 0.2s;

      &.active {
        opacity: 1;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover,
        &:focus {
          background: #263c61;
          color: #fff;
        }
      }

      .screenshot {
        display: none;

        &.active {
          display: block;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.cash-payment-quota-percent {
  transform: scale(1.2);
  padding-left: 27px;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: gray;
    background-color: gray;
  }

  .custom-control-label {
    left: 45px;
  }

  .custom-control-label::before {
    background-color: green;
  }

  .custom-control-label::after {
    background-color: white !important;
  }
}