body {
    position: relative;
    margin: 0;
    background-color: $bg-color;
    color: $black;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h6 {
    font-size: 1rem;
}

small {
    display: inline-block;
    line-height: 1.2;
}

.text-success {
    color: #46c15a !important;
}

.text-danger {
    color: $cherry !important;
}

.main-panel {
    min-height: calc(100vh - 122px); // navbar and footer height

    .display-size-alert {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 70px 20px;
        background: $white;
        z-index: 200;
    }
}

.text-muted {
    color: rgba($dark-gray, 0.5);
}

.color-sample {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: $border-radius-sm;
    background-color: $medium-gray;
}

.main-loader {
    position: relative;
    height: calc(100vh - 200px);

    .page-loader {
        height: 100%;
    }
}

footer {
    position: relative;
    width: 100%;
    min-height: $footer-height;
    //left:0;
    //bottom: 0;
    padding: 20px 1rem;
    text-align: center;
    background: #e6e8ec;//rgba($black, 0.1);
    z-index: 500;

    .footer-info {
        padding: 0;
        list-style: none;

        .info {
            display: inline-block;
            padding: 0 10px;
            margin-bottom: 5px;
            color: inherit !important;

            &:not(:last-child) {
                border-right: 1px solid $black;
            }
        }
    }
}

.notary-error-message {
  color: red;
  padding: 10px 25px;
  max-width: 400px;
  font-style: italic;
  font-size: 13px;
}