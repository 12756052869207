.navbar {
    //border-bottom: 1px solid $border-gray;
    box-shadow: $box-shadow;
    z-index: 500;

    .navbar-brand {
        margin-right: 0;
    }

    .navbar-toggler {
        border-color: transparent !important;
        padding-left: 0;
        outline: none;
    }

    .navbar-nav {
        padding: 0;

        &.navbar-right {
            flex-direction: row-reverse;
        }

        .nav-item {
            font-size: 1rem;
            font-weight: 500;

            .nav-link {
                color: rgba($black, 0.7);

                &:hover, &:focus {
                    color: rgba($black, 0.9);
                }

                &.nav-icon {
                    display: inline-flex;

                    .icon {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
