.modal {
    .modal-bgr {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($black, 0.4);
        z-index: -1;
        @include backdrop-filter(blur(6px));
    }

    .modal-dialog {
        .modal-content {
            border: none;
            border-radius: $border-radius-min;

            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                z-index: 5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
