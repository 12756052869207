.contract-form {
  position: relative;

  .form-heading {
    .price {
      display: block;
      line-height: 1;
      font-weight: 600;
      background: $white;
    }

    .loadbar {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 8px;
      background: $light-gray;
      border-radius: 0 0 $border-radius-min $border-radius-min;
      overflow: hidden;

      .percents {
        display: block;
        width: 0;
        height: 10px;
        background: linear-gradient(to right, $success-color, $warning-color);
        transition: width 0.5s;

        &.monotone {
          background: $warning-color;
        }
      }
    }
  }

  @import 'form-steps';

  .guest-form-card {
    display: flex;
    position: relative;
    padding: 0;
    overflow: hidden;

    .hero-image {
      flex: 50% 0 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      flex: 50% 0 0;
      padding: 25px 50px;
      color: $black;

      .header {
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 15px;
      }

      .description {
        margin-bottom: 20px;
        font-size: 13px;
      }

      .name {
        width: 100%;
        margin-bottom: 0;
      }

      .phone {
        margin-right: 10px;
      }

      .phone, .email {
        width: calc(50% - 5px);
        margin-bottom: 0;
        float: left;
      }

      .help-block {
        color: red;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .login-link {
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      .submit {
        width: 100%;
        margin-bottom: 10px;
      }

      .skip-link {
        font-size: 13px;
        text-align: center;
        text-decoration: underline;
        color: inherit;
        display: block;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .guest-form-card ~ .lady {
    position: absolute;
    top: 0;
    right: -90px;
    width: 120px;
    height: 500px;
    background: url("../img/lady/lady-1.svg") 50% 50% no-repeat;
    background-size: contain;
  }

  .sticky-sidebar {
    position: sticky;
    top: 15px;

    .checkout-info, .bar-info {
      position: relative;
      min-height: 80px;
      background: rgba($black, 0.02);
    }

    .checkout-info {
      .price, .points, .available-points {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;

        label {
          margin-bottom: 0;
          font-weight: 500;
        }

        strong {
          display: flex;
        }

        .icon {
          font-size: 30px;
        }
      }

    }

    .info-box {
      position: relative;
      min-height: 200px;
      background: rgba($black, 0.02);

      .icon-cont {
        position: absolute;
        text-align: center;
        color: rgba($black, 0.2);
        top: calc(50% - 4rem);
        left: calc(50% - 4rem);

        .icon {
          font-size: 8rem;
        }
      }

      .lady {
        position: absolute;
        top: 0;
        right: -110px;
        width: 120px;
        height: 500px;
        background: url("../img/lady/lady-1.svg") 50% 50% no-repeat;
        background-size: contain;
      }

      &.active {
        .lady {
          background-image: url("../img/lady/lady-2.svg");
        }
      }

      &.downside {
        .lady {
          top: -140px;
        }

        &.active {
          .lady {
            right: -140px;
            width: 134px;
            background-image: url("../img/lady/lady-3.svg");
          }
        }
      }
    }
  }

  .partner, .director, .interpreter, .company-property, .signing-rights {
    position: relative;

    .remove {
      position: absolute;
      padding: 0.2rem 0.7rem 0;
      top: $card-spacer-x;
      right: $card-spacer-x;
      border-radius: $border-radius-min;
      font-size: 18px;
      text-align: center;
      background-color: rgba($black, 0.05);
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.3s, color 0.3s;
      z-index: 5;

      &:hover {
        color: $danger-color;
        opacity: 1;
      }
    }
  }

  .company-info {
    .company-property {
      .remove {
        top: 0;
        right: 0;
        opacity: 0;
      }

      &:hover {
        .remove {
          opacity: 0.6;
        }
      }

      .error-area {
        top: initial;
        bottom: 0;
      }
    }

    .company-property-disclaimer {
      font-weight: 500;
      line-height: 1.2;
    }
  }

  .shares {
    .input-group-text {
      font-size: 20px;
    }

    .percent-wrapper {
      position: relative;
    }

    .info-button {
      position: absolute;
      display: inline-block;
      width: 26px;
      height: 26px;
      right: -40px;
      bottom: 5px;
      padding: 0;
      font-size: 26px;
      line-height: 26px;
      text-align: center;

      @extend .btn-primary;
    }
  }

  .representative {
    padding-left: 1.5rem;
    border-left: 1px solid $border-gray;
  }

  .remove-representative {
    position: absolute;
    right: -20px;
    top: 40px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $danger-color;
    }
  }

  .add-from-partners {
    display: inline-block;
  }

  .signing-rights {
    .signing-right {
      position: relative;

      &:hover {
        & > .buttons {
          .add-item {
            //@extend .btn-primary;
          }

          .remove-item {
            //@extend .btn-danger;
          }
        }
      }
    }

    .buttons {
      //text-align: right;
      line-height: 1;
      padding: 0;
    }

    .add-item, .remove-item {
      display: inline-block;
      width: 17px;
      height: 17px;
      padding: 0;
      font-size: 17px;
      line-height: 17px;
      text-align: center;
      //background: rgba($black, 0.2);
    }

    .signing-group {
      position: relative;
      padding: 5px 0 0 5px;
      border: 1px solid $border-gray;
      border-radius: $border-radius-min;
      margin-bottom: 5px;

      &.has-conflict {
        border: 1px solid $warning-color;
        background: rgba($warning-color, 0.15);
      }

      &:not(:last-child) {
        margin-bottom: 40px;

        &:after {
          position: absolute;
          display: inline-block;
          left: 0;
          bottom: -25px;
          line-height: 1;
          content: attr(data-or);
        }
      }

      .signing-director {
        padding: 0 20px 5px 0;

        &:not(.last) {
          &:after {
            position: absolute;
            display: inline-block;
            right: 6px;
            top: 0.375rem;
            content: "&";
          }
        }
      }

      &:hover {
        .add-item {
          //@extend .btn-primary;
        }

        .remove-item {
          //@extend .btn-danger;
        }
      }

      .buttons {
        width: 17px;
        max-width: 17px;

        .remove-item {
          margin-bottom: 3px !important;
        }
      }

      .remove-group {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  .terms {
    .plan {
      //display: flex;
      //justify-content: space-between;
      //align-items: center;
      min-height: 80px;
      padding: 1rem 1rem 0.3rem;
      //border: 1px solid $border-gray;
      box-shadow: $box-shadow;
      border-radius: $border-radius-min;
      background: $white;
      font-weight: bold;
      cursor: pointer;

      .plan-name {
        text-transform: uppercase;

        .icon {
          float: left;
          font-size: 24px;
        }
      }

      .plan-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .price {
        font-size: 26px;
      }

      &.selected {
        background: rgba($success-color, 0.15);
      }
    }

    .term {
      position: relative;
      //border: 1px solid $border-gray;
      box-shadow: $box-shadow;
      border-radius: $border-radius-min;
      background: $white;

      .score {
        position: absolute;
        display: flex;
        justify-content: center;
        border-radius: 0 0 $border-radius-min $border-radius-min;
        background: $warning-color;
        top: 0;
        right: 1rem;
        padding: 2px 10px;

        .icon {
          margin-top: 2px;
          font-size: 18px;
        }
      }

      .info-icon {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 28px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $info-color;
        }
      }

      .term-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 5px;
        bottom: 3px;
      }
    }

    .sub-terms {
      .sub-term {
        position: relative;
        padding: 7px 5px;
        border-top: 1px solid $border-gray;
        cursor: pointer;

        &:hover {
          background: rgba($success-color, 0.15);

          .info-icon {
            color: $primary-color;

            &:hover {
              color: $info-color;
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid $border-gray;
        }

        .info-icon {
          color: transparent;
          font-size: 24px;
          top: auto;
          left: 2px;
          bottom: 2px;
        }
      }
    }

    .suggest {
      background: rgba($warning-color, 0.15);
    }

    .term-layout {
      border: 1px dashed $border-gray;
      border-radius: $border-radius-min;
      color: $border-gray;
    }

    .add-button,
    .info-button {
      position: absolute;
      display: inline-block;
      width: 26px;
      height: 26px;
      left: 5px;
      bottom: 5px;
      padding: 0;
      font-size: 26px;
      line-height: 26px;
      text-align: center;

      @extend .btn-primary;
    }

    .add-button + .info-button {
      left: 35px;
    }

    .selected-term {
      flex-direction: row-reverse;

      .add-button {
        @extend .btn-danger;

        .icon {
          transform: rotate(45deg);
        }
      }
    }

    .term, .term-layout {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 2rem 1rem;

      h5 {
        padding: 1rem 0;
      }
    }
  }

  .obligations {
    .add-item, .remove-item {
      display: inline-block;
      width: 17px;
      height: 17px;
      padding: 0;
      font-size: 17px;
      line-height: 17px;
      text-align: center;
    }

    .sequence .remove-item {
      margin-bottom: 3px !important;
    }
  }

  .pick-up-sequences {
    .sequence {
      position: relative;
      padding: 5px 0 0 5px;
      border: 1px solid $border-gray;
      border-radius: $border-radius-min;
      margin-bottom: 5px;

      .buttons {
        padding: 0;
        width: 17px;
        max-width: 17px;
        line-height: 1;

        .add-item {
          margin-bottom: 3px;
        }
      }
    }

    label {
      font-weight: 500;
      line-height: 46px;
    }

    .partner {
      padding: 0 20px 5px 0;

      &:not(.last) {
        &:after {
          position: absolute;
          display: inline-block;
          right: 6px;
          top: 0.375rem;
          content: "&";
        }
      }
    }
  }

  .switch-container {
    max-width: 90px;
    padding-top: 7px;
  }

  #info_text {
    overflow-y: auto;
  }
}

.upsell {
  position: fixed !important;
  z-index: 9999;
  transform: translateX(-50%);
  width: 350px;
  top: 0;
  max-height: 53px;
  left: 50%;
  overflow: hidden;
  transition: all 0.2s;

  display: none;
  background: $orange !important;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.1;
  cursor: pointer;

  &.show {
    display: flex;
  }

  .upsell-icon {
    flex: 25px 0 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  h5 {
    color: $white;
    margin-bottom: 0.5rem;
  }

  .text {
    opacity: 0;
    transition: all 0.2s;
  }

  &.opened {
    max-height: 500px;

    .text {
      opacity: 1;
    }
  }
}

.card.form-loading {
  position: relative;

  .card-body, .card-footer {
    opacity: 0.5;
  }

  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &:after {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-radius: 50%;
    content: "";
    border: 4px solid rgba($primary-color, 0.2);
    border-top-color: $primary-color;
    animation: loader-spin .7s infinite linear;;
  }
}

.select-wrapper label {
  margin: 0;
  position: relative;
  width: 100%;

  .text {
    position: absolute;
    left: 0.75rem;
    width: 100px;
    line-height: 37px;
  }

  select {
    padding-left: calc(100px + 0.75rem);
  }

  select.partner-language {
    padding-left: calc(215px + 0.75rem);
  }

  select.partner-country {
    padding-left: calc(155px + 0.75rem);
  }

  .width-140px {
    width: 140px;
  }

  .width-200px {
    width: 200px;
  }
}


