@mixin filter($filter) {
    -webkit-filter: $filter;
    -moz-filter: $filter;
    -ms-filter: $filter;
    -o-filter: $filter;
    filter: $filter;
}

@mixin backdrop-filter($filter) {
    -webkit-backdrop-filter: $filter;
    -moz-backdrop-filter: $filter;
    -ms-backdrop-filter: $filter;
    -o-backdrop-filter: $filter;
    backdrop-filter: $filter;
}
