.btn {
    border: none;
    border-radius: $border-radius-min !important;

    @include btn-styles($default-color);

    &:hover,
    &:focus {
        outline: 0 !important;
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        outline: 0 !important;
    }

    //icons inside buttons
    &.btn-icon {
        display: inline-flex;

        .icon {
            font-size: $btn-icon;
        }

        &.btn-sm {
            .icon {
                font-size: $btn-icon-sm;
            }
        }

        &.btn-lg {
            .icon {
                font-size: $btn-icon-lg;
            }
        }
    }
}

// Apply the mixin to the buttons
// .btn-default { @include btn-styles($default-color); }
.btn-primary {
    @include btn-styles(lighten($primary-color, 10%));
}

.btn-secondary {
    @include btn-styles(lighten($secondary-color, 10%));
}

.btn-success {
    @include btn-styles(lighten($success-color, 10%));
}

.btn-info {
    @include btn-styles(lighten($info-color, 10%));
}

.btn-warning {
    @include btn-styles(lighten($warning-color, 10%));
}

.btn-danger {
    @include btn-styles(lighten($danger-color, 10%));
}

// .btn-neutral { @include btn-styles($white); }

.btn-outline-default {
    @include btn-outline-styles($default-color);
}

.btn-outline-primary {
    @include btn-outline-styles($primary-color);
}

.btn-outline-success {
    @include btn-outline-styles($success-color);
}

.btn-outline-info {
    @include btn-outline-styles($info-color);
}

.btn-outline-warning {
    @include btn-outline-styles($warning-color);
}

.btn-outline-danger {
    @include btn-outline-styles($danger-color);
}

.btn-outline-neutral {
    @include btn-outline-styles($white);

    &:hover,
    &:focus {
        color: darken($default-color, 10%);
        background-color: $white;
    }
}

.btn-neutral {
    @include btn-styles($white);
    color: $primary-color;

    &:hover,
    &:focus {
        color: $white;
    }

    &.btn-border {
        &:hover,
        &:focus {
            color: $default-color;
        }

        &:active,
        &.active,
        .open > &.dropdown-toggle {
            background-color: $white;
            color: $default-color;
        }
    }

    &.btn-link:active,
    &.btn-link.active {
        background-color: transparent;
    }
}

.btn {
    &:disabled,
    &[disabled],
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.btn-simple {
    border-color: $default-color;
    background-color: transparent;
}

.btn-simple,
.btn-link {
    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: transparent;
        }
    }
}

.btn-link {
    border: none;
    background-color: transparent;
}

.btn-full {
    width: 100%;
}

.btn-wd {
    min-width: 140px;
}

.btn-group.select {
    width: 100%;
}

.btn-group.select .btn {
    text-align: left;
}

.btn-group.select .caret {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

.btn-group {
    .btn + .btn {
        margin-left: -3px;
    }

    .btn {
        &:focus {
            background-color: $info-color !important;
        }
    }
}
