@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?17bb7b');
  src: url('/fonts/icomoon.eot?17bb7b#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?17bb7b') format('truetype'),
  url('/fonts/icomoon.woff?17bb7b') format('woff'),
  url('/fonts/icomoon.svg?17bb7b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  &.icon-arrow-right:before {
    content: "\e901";
  }

  &.icon-arrow-down {
    transform: rotate(90deg);

    &:before {
      content: "\e901";
    }
  }

  &.icon-arrow-left {
    transform: rotate(180deg);

    &:before {
      content: "\e901";
    }
  }

  &.icon-arrow-up {
    transform: rotate(-90deg);

    &:before {
      content: "\e901";
    }
  }

  &.icon-calendar:before {
    content: "\e902";
  }

  &.icon-cart:before {
    content: "\e903";
  }

  &.icon-checkbox-empty:before {
    content: "\e904";
  }

  &.icon-checkbox-filled:before {
    content: "\e906";
  }

  &.icon-diamond:before {
    content: "\e905";
  }

  &.icon-close:before {
    content: "\e907";
    display: inline-block;
  }

  &.icon-close-lg:before {
    content: "\e907";
  }

  &.icon-close-sm:before {
    content: "\e908";
  }

  &.icon-delete:before {
    content: "\e909";
  }

  &.icon-help:before {
    content: "\e90a";
  }

  &.icon-user:before {
    content: "\e91a";
  }

  &.icon-info:before {
    content: "\e911";
  }

  &.icon-document:before {
    content: "\e912";
  }

  &.icon-shield:before {
    content: "\e913";
  }

  &.icon-radio-empty:before {
    content: "\e914";
  }

  &.icon-radio-filled:before {
    content: "\e915";
  }

  &.icon-infinity:before {
    content: "\e916";
  }

  &.icon-details:before {
    content: "\e917";
  }

  &.icon-obligation:before {
    content: "\e918";
  }

  &.icon-lock:before {
    content: "\e919";
  }

  &.icon-percent:before {
    content: "\e90b";
  }

  &.icon-plus:before {
    content: "\e90c";
  }

  &.icon-position:before {
    content: "\e90d";
  }

  &.icon-report:before {
    content: "\e90e";
  }

  &.icon-tick:before {
    content: "\e90f";
  }

  &.icon-user-management:before {
    content: "\e910";
  }

  &.icon-bar-chart:before {
    content: "\e900";
  }
}
