.table {
    thead {
        th {
            border-top: none;
            border-bottom: 1px solid $border-gray;
        }
    }

    .ellipsis {
        position: relative;
        display: inline-block;
        width: 12px;
        height: 20px;
        font-weight: bold;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '...';
        }
    }

    .table-action {
        cursor: pointer;
        .icon {
            font-size: 22px;

            &.icon-document {
                font-size: 18px;
            }
        }

        &.disabled {
            color: $text-muted;
            cursor: default;
        }
    }
}
