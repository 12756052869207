.card {
    border: none;
    background: none;

    .card-header, .card-footer {
        min-height: 48px;
        padding: $card-spacer-y 0;
        background: transparent;
        border: none;
    }

    .card-body {
        //border: 1px soid $border-gray;
        background: $white;
        border-radius: $border-radius-min;
        box-shadow: $box-shadow;
    }

    .register-footer.card-footer{
        border-top: 1px solid rgba(38, 60, 97, 0.125);
    }
}
