@import 'colors';

//overwrite bootstrap variables
$font-color: $black !default;
$fill-font-color: rgba($placeholder-gray, 0.8);
$input-placeholder-color: $placeholder-gray;
$font-size-base: 0.9rem;
$font-family-sans-serif: 'Work Sans', Arial, sans-serif;
$sans-serif-family: 'Work Sans', Arial, sans-serif;
$line-height-base: 1.6;
$card-spacer-x: 1rem !default;

$btn-icon: 1.5rem;
$btn-icon-sm: 1.2rem;
$btn-icon-lg: 1.7rem;

$footer-height: 64px;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$border-radius-min: 2px;

$box-shadow: 0 1px 3px rgba($black, 0.05), 0 1px 2px rgba($black ,0.1);
