.checkout {
  .stripe-card-container {
    border: 1px solid $border-gray;
    border-radius: 2px;
    width: 100%;
    padding: $input-padding-x $input-padding-y;
  }

  .form-control {
    border: 1px solid $border-gray;
  }
}

.header-title {
  font-weight: 500;
  font-size: 21px;
  color: $black;
}

.kassa {
  .card-info {
    padding: 0;
    //box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.1);
    //border-radius: 10px;
  }

  .apply-container {
    padding: 0 15px 0 0;
  }

  .dashed-line {
    border-top: 1px dashed;
    margin-top: 16px;
  }

  .stripe-container {
    padding: 0 0 0 15px;
    margin-top: 8px;
  }

  .lawyer-price {
    font-weight: bold;
    color: $cherry;
  }

  .price-line {
    display: flex;
    align-items: center;

    .text {
      flex: 1;
    }

    .value {
      width: 90px;
      font-size: 20px;
      text-align: right;
    }
  }
}

.discount {
  font-weight: bold !important;
  color: $cherry !important;
}

.prices .col-md-2 {
  white-space: nowrap;
}

.row {
  margin-right: 0;
}

.apply-container {
  button {
    background-color: #B1B1B1;
  }
}

.read-more-btn {
  border: none;
  background-color: #fff;
  text-decoration: underline;
  color: #2354FF;
  display: inline;
  padding: 0;
}

#card-pay {
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  font-size: 24px;
  width: 100%;
  background-color: #345487;
  padding: 20px;

  &.bottom-button {
    max-width: 493px;
  }
}

.elisa-background {
  width: 266px;
  height: 675px;
  position: absolute;
  right: 8%;
  top: 8%;
  background: url("../../../img/lady/lady-6.svg") no-repeat;
}

.payment-types-images {
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;

  &.full-width {
    width: 100%;
  }

  .card {
    width: 50px;
    height: 33px;
    margin: 2px;
  }
}

@media (max-width: 1500px) {
  .elisa-background {
    right: 0;
    top: 8%;
  }
}

.col-md-2 {
  flex: 0 0 16.6666666667%;
  max-width: 17.666667%;
}

.h4 {
  color: $black;
  font-size: 18px;
  font-weight: 500;
}

.border-on-payment {
  border-bottom: 1px solid #758399;
  border-top: 1px solid #758399;
  margin: 14px 0;

  .form-group {
    margin-bottom: 0;
  }
}

#payment-method {
  label {
    min-width: 90%;
    margin-bottom: 0 !important;
  }

  .mdi-credit-card {
    margin-left: 17px;
    font-size: 18px;
  }
}

.mdi-chevron-down,
.mdi-chevron-up {
  font-size: 28px;
}

.dashed-line {
  margin: 10px auto;
}

.contract-info {
  border-bottom: 1px solid #758399;

  span {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;

  }
}

.btn.btn-link:hover,
.btn.btn-link:active,
.btn.btn-link:focus {
  background: white !important;
  color: black !important;
}

.left-section {
  padding: 1rem 1rem 1rem 0;

  .h4 {
    border-bottom: 1px solid $black;
    padding-bottom: 13px;
    margin: 0 0 13px 0;
  }

  .font-size-22 {
    font-size: 22px;
    color: $black;
    font-weight: 700;
    line-height: 19.2px;
  }

  .call_us {
    .h4 {
      border: none;
    }
  }
}

//Section Animation
.content-loading {
  background-color: white;
  position: absolute;
  z-index: 15;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5px 45px;
}

.lds-grid {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.4);
}

.lds-grid:after {
  content: "";
  display: table;
  overflow: auto;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #5f5c5c;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

#animation-loading {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 501;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;

  .square {
    position: relative;
    width: 570px;
    height: 325px;
    background-color: #fff;
    border: 1px solid #6C757D;

    p {
      margin: 15px 0 0 15px;
    }

    p.processing {
      font-weight: 700;
      font-size: 20px;
      color: $black;
    }

    .offering-prices {
      height: 100%;
      width: 100%;
      padding: 20px 65px;
      position: absolute;
      top: 0;
      z-index: 1;
      background-color: #fff;
      animation: hide-content-loading 0.8s;
      animation-delay: 2.9s;
      animation-fill-mode: forwards;
      transition: all 1s;

      a {
        color: #0b6dfb;
        cursor: pointer;
        text-decoration: none;
      }

      .line {
        display: flex;
        justify-content: space-between;
        position: relative;
      }
    }

    @keyframes hide-content-loading {
      0% {
        z-index: 1;
        transform: scale(0);
      }
      50% {
        z-index: 1;
        transform: scale(0);
      }

      100% {
        z-index: 50;
        transform: scale(1);
      }
    }

    span {
      font-size: 18px;
      font-weight: 500;
    }

    .heading {
      font-weight: bold;
      color: $black;
      font-weight: 700;
      font-size: 20px;
    }

    .greetings {
      margin-bottom: 15px;
    }

    .elisa-offer {

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        display: block;
        overflow: auto;
        background-color: $black;
        width: 100%;
        height: 2px;
      }
    }

    .green-area {
      color: green !important;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;

      i {
        font-weight: 500;
        font-size: 50px;
        line-height: 50px;
      }
    }

    .btn-continue {
      i {
        font-size: 23px;
        line-height: 22px;
        margin-left: 12px;
      }
    }

    .lady-6 {
      background: url("../../../img/lady/lady-6.svg") no-repeat;
      width: 232px;
      height: 265px;
      position: absolute;
      right: 0;
      top: 11%;
    }

    p.loading-texts:after {
      content: "";
      font-size: 20px;
      margin-top: 20px;
      animation: change-text 3.6s 0s;
      animation-fill-mode: forwards;
    }


    @keyframes change-text {
      0% {
        content: "Verarbeite Eingaben …";
      }
      24% {
        content: "Verarbeite Eingaben …";
      }
      25% {
        content: "Gleiche Datenbanken ab … ";
      }
      49% {
        content: "Gleiche Datenbanken ab …";
      }
      50% {
        content: "Erstelle Dokumente … ";
      }
      74% {
        content: "Erstelle Dokumente … ";
      }
      75% {
        content: "Führe Preisvergleich durch … ";
      }
      100% {
        content: "Führe Preisvergleich durch … ";
      }
    }
  }
}

.demo-pdfs-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  flex-wrap: wrap;
  margin-left: 45px;
  align-content: center;

  div {
    width: 22%;
  }

  a {
    font-size: 18px !important;
    color: $black;
    cursor: pointer;
  }

  i {
    font-size: 350%;
  }
}

#demo-preview {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;

  .close-preview {
    position: fixed;
    right: 20px;
    z-index: 2000;
    font-size: 30px;
    color: $black;
    top: 20px;
    cursor: pointer;
  }

  .content-loading {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    z-index: -1;
    left: 50%;
    width: 100%;
    height: 100vh;
    top: 50%;
    transform: translate(-50%, -50%);

    p.processing {
      font-weight: 700;
      position: fixed;
      color: $black;
      font-size: 26px;
      top: calc(50% - 102px);
      left: 50%;
      z-index: 2;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }

  object {
    margin: 0 auto;
    display: block;
  }
}