// Mixin for generating new styles
@mixin btn-styles($btn-color) {
    background-color: $btn-color;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover,
    &.active:focus,
    &.active:hover,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle:focus,
    .show > &.dropdown-toggle:hover {
        background-color: darken($btn-color, 10%) !important;
        color: $white !important;
        box-shadow: none !important;
    }

    &:not([data-action]):hover {
        box-shadow: none;
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $btn-color;
            border-color: $btn-color;
        }
    }

    // btn-neutral style
    @if $btn-color == $white {
        color: $primary-color;

        &.btn-danger {
            color: $danger-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                color: darken($danger-color, 10%) !important;
            }
        }

        &.btn-info {
            color: $info-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                color: darken($info-color, 10%) !important;
            }
        }

        &.btn-warning {
            color: $warning-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                color: darken($warning-color, 10%) !important;
            }
        }

        &.btn-success {
            color: $success-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                color: darken($success-color, 10%) !important;
            }
        }

        &.btn-default {
            color: $default-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus {
                color: darken($default-color, 10%) !important;
            }
        }

        &.active,
        &:active,
        &:active:focus,
        &:active:hover,
        &.active:focus,
        &.active:hover,
        .show > &.dropdown-toggle,
        .show > &.dropdown-toggle:focus,
        .show > &.dropdown-toggle:hover {
            background-color: $white !important;
            color: darken($primary-color, 10%) !important;
            box-shadow: none !important;
        }

        &:hover,
        &:focus {
            color: darken($primary-color, 10%) !important;

            &:not(.nav-link) {
                box-shadow: none;
            }

        }

    } @else {
        color: $white;
    }

    &.btn-simple {
        color: $btn-color;
        border-color: $btn-color;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            color: darken($btn-color, 10%);
            border-color: darken($btn-color, 10%);
            box-shadow: none;
        }
    }

    &.btn-link {
        color: $btn-color;

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            background-color: transparent;
            color: darken($btn-color, 10%);
            text-decoration: none;
            box-shadow: none;
        }
    }

    &.loading {
        position: relative;
        color: transparent !important;

        &:before {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
            border: 2px solid rgba($white, 0.2);
            border-top-color: $white;
            content: "";
            animation: loader-spin .5s infinite linear;
        }
    }
}

@mixin btn-outline-styles($btn-color) {
    background: transparent;
    border: 2px solid $btn-color !important;
    color: $btn-color;
    opacity: 1;

    &:hover,
    &:focus,
    &:active,
    &:focus:active,
    &.active,
    .open > &.dropdown-toggle {
        background-color: $btn-color !important;
        color: $fill-font-color !important;
        border-color: $btn-color !important;

        .caret {
            border-top-color: $fill-font-color !important;
        }
    }

    .caret {
        border-top-color: $white !important;
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: transparent !important;
            border-color: $btn-color !important;
        }
    }
}

@mixin rotate-180() {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
