.offset-right-12 {
  margin-right: 100%;
}

.offset-right-11 {
  margin-right: 91.66666667%;
}

.offset-right-10 {
  margin-right: 83.33333333%;
}

.offset-right-9 {
  margin-right: 75%;
}

.offset-right-8 {
  margin-right: 66.66666667%;
}

.offset-right-7 {
  margin-right: 58.33333333%;
}

.offset-right-6 {
  margin-right: 50%;
}

.offset-right-5 {
  margin-right: 41.66666667%;
}

.offset-right-4 {
  margin-right: 33.33333333%;
}

.offset-right-3 {
  margin-right: 25%;
}

.offset-right-2 {
  margin-right: 16.66666667%;
}

.offset-right-1 {
  margin-right: 8.33333333%;
}

.offset-right-0 {
  margin-right: 0;
}

@media (min-width: 576px) {
  .offset-sm-right-12 {
    margin-right: 100%;
  }
  .offset-sm-right-11 {
    margin-right: 91.66666667%;
  }
  .offset-sm-right-10 {
    margin-right: 83.33333333%;
  }
  .offset-sm-right-9 {
    margin-right: 75%;
  }
  .offset-sm-right-8 {
    margin-right: 66.66666667%;
  }
  .offset-sm-right-7 {
    margin-right: 58.33333333%;
  }
  .offset-sm-right-6 {
    margin-right: 50%;
  }
  .offset-sm-right-5 {
    margin-right: 41.66666667%;
  }
  .offset-sm-right-4 {
    margin-right: 33.33333333%;
  }
  .offset-sm-right-3 {
    margin-right: 25%;
  }
  .offset-sm-right-2 {
    margin-right: 16.66666667%;
  }
  .offset-sm-right-1 {
    margin-right: 8.33333333%;
  }
  .offset-sm-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .offset-md-right-12 {
    margin-right: 100%;
  }
  .offset-md-right-11 {
    margin-right: 91.66666667%;
  }
  .offset-md-right-10 {
    margin-right: 83.33333333%;
  }
  .offset-md-right-9 {
    margin-right: 75%;
  }
  .offset-md-right-8 {
    margin-right: 66.66666667%;
  }
  .offset-md-right-7 {
    margin-right: 58.33333333%;
  }
  .offset-md-right-6 {
    margin-right: 50%;
  }
  .offset-md-right-5 {
    margin-right: 41.66666667%;
  }
  .offset-md-right-4 {
    margin-right: 33.33333333%;
  }
  .offset-md-right-3 {
    margin-right: 25%;
  }
  .offset-md-right-2 {
    margin-right: 16.66666667%;
  }
  .offset-md-right-1 {
    margin-right: 8.33333333%;
  }
  .offset-md-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .offset-lg-right-12 {
    margin-right: 100%;
  }
  .offset-lg-right-11 {
    margin-right: 91.66666667%;
  }
  .offset-lg-right-10 {
    margin-right: 83.33333333%;
  }
  .offset-lg-right-9 {
    margin-right: 75%;
  }
  .offset-lg-right-8 {
    margin-right: 66.66666667%;
  }
  .offset-lg-right-7 {
    margin-right: 58.33333333%;
  }
  .offset-lg-right-6 {
    margin-right: 50%;
  }
  .offset-lg-right-5 {
    margin-right: 41.66666667%;
  }
  .offset-lg-right-4 {
    margin-right: 33.33333333%;
  }
  .offset-lg-right-3 {
    margin-right: 25%;
  }
  .offset-lg-right-2 {
    margin-right: 16.66666667%;
  }
  .offset-lg-right-1 {
    margin-right: 8.33333333%;
  }
  .offset-lg-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .offset-xl-right-12 {
    margin-right: 100%;
  }
  .offset-xl-right-11 {
    margin-right: 91.66666667%;
  }
  .offset-xl-right-10 {
    margin-right: 83.33333333%;
  }
  .offset-xl-right-9 {
    margin-right: 75%;
  }
  .offset-xl-right-8 {
    margin-right: 66.66666667%;
  }
  .offset-xl-right-7 {
    margin-right: 58.33333333%;
  }
  .offset-xl-right-6 {
    margin-right: 50%;
  }
  .offset-xl-right-5 {
    margin-right: 41.66666667%;
  }
  .offset-xl-right-4 {
    margin-right: 33.33333333%;
  }
  .offset-xl-right-3 {
    margin-right: 25%;
  }
  .offset-xl-right-2 {
    margin-right: 16.66666667%;
  }
  .offset-xl-right-1 {
    margin-right: 8.33333333%;
  }
  .offset-xl-right-0 {
    margin-right: 0;
  }
}