@media screen and (max-width: $screen-xl + 90px) {
  .contract-form {
    .form-steps-section {
      width: 100%;
      max-width: 100%;
      margin: 10px auto 30px;

      .form-steps {
        top: 0;
        position: relative;
        flex-direction: row;

        .step {
          margin: 0 2vw;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:not(:first-child):before, &:not(:last-child):after {
            top: 50%;
            left: initial;
            right: initial;
            width: 3vw;
            height: 1px;
          }

          &:before {
            right: calc(46px + 0.5vw) !important;
          }

          &:after {
            left: calc(46px + 0.5vw) !important;
          }
        }
      }

      &.guest-form-steps-section {
        flex:1;

        .form-steps {
          .step {
            margin: 0 8px;


            &:not(:last-child):after {
              height: 1px;
              width: 16px;
            }

            &:before {
              display: none;
            }

            &:after {
              left: 40px !important;
            }

            &.screenshot-active:before {
              content: "";
              position: absolute;
              display: block;
              width: 0;
              background: transparent;
              height: auto;
              top: 52px;
              left: -52px;
              right: 0;
              border: 22px solid transparent;
              border-top: 0;
              border-bottom: 22px solid #263c61;
              transform: translate(calc(100% + 4px), -50%);
            }
          }
        }

        .screenshot-placeholder {
          background: #fff;
          position: absolute;
          top: 65px;
          left: 70px;
          width: 1000px;
          z-index: 9999;
          padding: 0;
          display: none;
          opacity: 0;
          border: 1px solid #263c61;
          box-shadow: 0 1px 3px rgb(38 60 97 / 5%), 0 1px 2px rgb(38 60 97 / 10%);
          transition: all 0.2s;

          &.active {
            opacity: 1;
          }

          .icon {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 25px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover,
            &:focus {
              background: #263c61;
              color: #fff;
            }
          }

          .screenshot {
            display: none;

            &.active {
              display: block;
            }

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .sticky-sidebar {
      .info-box {
        .lady {
          width: 72px;
          right: -70px;
          height: 300px;
        }

        &.downside {
          .lady {
            top: -100px;
          }

          &.active {
            .lady {
              width: 80px;
              right: -86px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xl - 150px) {
  .contract-form {
    .sticky-sidebar {
      .info-box {
        .lady {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .contract-form {
    display: none;
  }
}

@media screen and (max-width: $screen-md) {
  .navbar {
    .navbar-nav {
      &.navbar-right {
        flex-direction: row;
      }
    }
  }

  .contract-form {
    .form-steps-section {
      .form-steps {
        .step {
          margin: 0 3vw;

          &:before, &:after {
            display: none !important;
          }
        }
      }
    }
  }
}
