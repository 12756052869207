//.vue-suggestion .vs__input {
//    @extend .form-control;
//}
//
//.vue-suggestion .vs__list {
//    width: 100%;
//    text-align: left;
//    border: none;
//    border-top: none;
//    max-height: 400px;
//    overflow-y: auto;
//    border-bottom: 1px solid $input-border-gray;
//    position: relative;
//    background: $white;
//    z-index: 100;
//}
//
//.vue-suggestion .vs__list .vs__list-item {
//    background-color: $white;
//    padding: 10px;
//    border-left: 1px solid $input-border-gray;
//    border-right: 1px solid $input-border-gray;
//}
//
//.vue-suggestion .vs__list .vs__list-item:last-child {
//    border-bottom: none;
//}
//
//.vue-suggestion .vs__list .vs__list-item:hover {
//    background-color: rgba($black, 0.1) !important;
//}
//
//.vue-suggestion .vs__list,
//.vue-suggestion .vs__loading {
//    position: absolute;
//}
//
//.vue-suggestion .vs__list .vs__list-item {
//    cursor: pointer;
//}
//
//.vue-suggestion .vs__list .vs__list-item.vs__item-active {
//    background-color: #f3f6fa;
//}

.search-results {
    position: absolute;
    width: 100%;
    max-height: 400px;
    padding: 0;
    list-style: none;
    text-align: left;
    border: none;
    border-top: none;
    border-bottom: 1px solid $input-border-gray;
    border-radius: 0 0 $border-radius $border-radius;
    background: $white;
    overflow-y: auto;
    z-index: 100;

    li {
        background-color: $white;
        padding: 10px;
        border-left: 1px solid $input-border-gray;
        border-right: 1px solid $input-border-gray;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
            border-radius: 0 0 $border-radius $border-radius;
        }

        &:hover {
            background-color: rgba($black, 0.1) !important;
        }

        &.active {
            background-color: #f3f6fa;
        }
    }
}
