.form-check {
    position: relative;
    padding-left: 0;
    line-height: 1;

    .form-check-label {
        display: inline-flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        line-height: 25px;

        .form-check-sign {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: -3px; //compensate icon padding
            font-size: 24px;
            cursor: pointer;
            transition: opacity 0.3s linear;
        }
    }

    &.disabled {
        .form-check-label {
            opacity: .5;
            cursor: default;
        }
    }

    input[type="checkbox"], input[type="radio"] {
        opacity: 0;
        position: absolute;
        visibility: hidden;

        &:disabled + .form-check-sign {
            opacity: 0.5;
        }
    }

    input[type="checkbox"] {
        + .form-check-sign .icon {
            &:before {
                @extend .icon-checkbox-empty;
            }
        }

        &:checked + .form-check-sign .icon {
            &:before {
                @extend .icon-checkbox-filled;
            }
        }
    }

    input[type="radio"] {
        + .form-check-sign .icon {
            &:before {
                @extend .icon-radio-empty;
            }
        }

        &:checked + .form-check-sign .icon {
            &:before {
                @extend .icon-radio-filled;
            }
        }
    }
}

