.dropdown {
    .dropdown-menu {
        border-radius: 0;

        &:before {
            position: absolute;
            display: inline-block;
            top: -12px;
            border-bottom: 11px solid rgba($black, 0.15);
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            content: "";
        }

        &:after {
            position: absolute;
            display: inline-block;
            top: -11px;
            border-bottom: 11px solid $white;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            content: "";
        }

        &-left:before {
            left: 12px;
        }
        &-right:before {
            right: 12px;
        }

        &-left:after {
            left: 13px;
        }
        &-right:after {
            right: 13px;
        }

        .dropdown-item {
            cursor: pointer;
        }
    }

    &.dropup {
        .dropdown-menu {
            &:before {
                top: auto;
                bottom: -12px;
                border-bottom: 0;
                border-top: 11px solid rgba($black, 0.15);
                //border-left: 11px solid transparent;
                //border-right: 11px solid transparent;
                //content: "";
            }

            &:after {
                top: auto;
                bottom: -11px;
                border-bottom: 0;
                border-top: 11px solid $white;
                //border-left: 10px solid transparent;
                //border-right: 10px solid transparent;
                //content: "";
            }
        }

    }
}

.dropdown-toggle {
    cursor: pointer;
}
