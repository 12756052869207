.form-group {
    position: relative;

    label, .label {
        font-weight: 500;
        max-width: 100%;
        hyphens: auto;
    }

    .info-area, .error-area {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        top: 1px;
        right: 1px;
        color: transparent;
        font-size: 24px;
        z-index: 10;
        cursor: pointer;
        transition: color 0.3s;

        @extend .icon;

        &:before {
            content: "\e911";
        }
    }

    .error-area {
        background: $white;
        color: $danger-color !important;

        &.warning {
            color: $warning-color !important;
        }
    }

    &:hover {
        .info-area {
            background: $white;
            color: $primary-color !important;

            &:hover {
                color: $info-color !important;
            }
        }
    }
}

.form-control {
    border: 1px solid $input-border-gray;
    border-radius: $border-radius-min !important;
    color: $font-color;
    box-shadow: none !important;
    -webkit-appearance:none; /* Safari and Chrome */
    -moz-appearance:none; /* Firefox */
    appearance:none;

    &.is-invalid {
        background-image: none;
    }

    &.has-warning {
        border: 1px solid $warning-color;
    }

    &.form-control-sm {
        height: calc(1.5em + 0.3rem + 2px);
        padding: 0.15rem 0.5rem;
    }

    &.has-icon {
        padding-right: calc(#{2 * $input-padding-x} + 16px);
    }

    &.success {
        background: rgba($success-color, 0.2);
    }

    &.danger {
        background: rgba($danger-color, 0.2);
    }
}

select.form-control {
    background: url('/img/icons/chevron-down.svg') no-repeat calc(100% - 0.75rem) 50%;
    background-size: 10px;
    padding-right: 25px;
}

.form-control.has-icon, .form-group .has-icon {
    & + .icon {
        position: absolute;
        top: 1px;
        right: 1px;
        padding: 0.375rem 0.35rem;
        font-size: 23px;
        color: $placeholder-gray;

        &.bgr {
            background: $border-gray;
        }
    }

    &.is-invalid + .icon {
        opacity: 0 !important;
    }
}

.check-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;

    .readonly {
        opacity: 0.7;
        cursor: default;
    }

    .icon {
        font-size: 24px;
        margin-left: -3px;
    }

    h6 {
        line-height: 1;
    }
}

.date-btn {
    position: relative;
    width: 40px;

    .form-control {
        position: absolute;
        padding-right: 26px !important;
        background: none !important;
        z-index: 2;
        cursor: pointer;
    }
}

label {
    font-weight: 500;
    max-width: 100%;
    hyphens: auto;
}

//.input-group {
//    .input-group-append, .input-group-prepend {
//        border-color: $input-border-gray;
//
//        .input-group-text {
//            background: $border-gray;
//        }
//    }
//}
