@import 'contract-form';
@import 'checkout';

.card-info {
  padding: 80px;
  //box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.1);
  //border-radius: 10px;

  .title {
    font-size: 40px;
    font-weight: bold;
  }

  .info-text {
    font-size: 16px;
    margin: 50px 0;
    color: rgba(38, 60, 97, 0.7);
  }
}

